import React,{Component} from "react";
import { Modal } from "react-bootstrap";
import "./TextModal.css";
class TextModal extends Component{
render(){
  return (
    <React.Fragment>
      <Modal show={this.props.show} onHide={this.props.handleClose}>
        {/* <Modal.Header closeButton></Modal.Header> */}
        <Modal.Body className="modal_body">
          {/* <p>
          Հայերեն Shqip ‫العربية‫العربية Български Català 中文简体 Hrvatski Česky Dansk Nederlands English Eesti Filipino Suomi Français ქართული Deutsch Ελληνικά ‫עברית‫עברית हिन्दी Magyar Indonesia Italiano Latviski Lietuviškai македонски Melayu Norsk Polski Português Româna Pyccкий Српски Slovenčina Slovenščina Español Svenska ไทย Türkçe
          </p> */}
          <p>
          Nec sane haec sola pernicies orientem diversis cladibus adfligebat. Namque et Isauri, quibus est
          </p>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}};

export default TextModal;
