import React, { Component } from "react";
import classes from "../calculaterevenue/calculaterevenue.module.css";
import { Container, Row, Col } from "react-bootstrap";
import {Link } from "react-router-dom";
import { BsFillExclamationCircleFill } from "react-icons/bs";
import { HiCurrencyEuro } from "react-icons/hi";
// import TextModal from "../content/TextModal";

class CalculateRevenue extends Component {
    // constructor(props){
    //   super(props);
    //   this.state={
    //     show:false,
    //   }
    // }
    // handleShow=()=>{
    //   this.setState({show:true})
    // }
    // handleClose=()=>{
    //   this.setState({show:false})
    // }
  render() {
    return (
      <Container>
        <Row>
          <Col>
          {/* <TextModal
          handleClose={this.handleClose}
          show={this.state.show}
          /> */}
            <div className="leftColum">
              <h4 className={classes.heading}>MES INFOS</h4>
              <div className={classes.left_div_wrapper}>
                <h6 className={classes.Estimation}>
                  Estimation impots
                  <Link to={{pathname: "https://nrgpartners.fr/"}} target="_blank">
                  <BsFillExclamationCircleFill style={{ marginLeft: 10, cursor:"pointer" }} 
                  // { /* onClick={this.handleShow} */ }
                  />
                  </Link>
                </h6>
                <h3 className={classes.Estimations}>
                  {/* {this.props.revenue * 12} */}
                  {this.props.TaxEstimate?this.props.TaxEstimate:0}
                  <HiCurrencyEuro
                    style={{ color: "black", marginTop: "-6px" }}
                  />
                </h3>

                <h6 className={classes.Estimation}>
                  Revenus mensuels nets
                  <Link to={{pathname: "https://nrgpartners.fr/"}} target="_blank">
                  <BsFillExclamationCircleFill style={{ marginLeft: 10, cursor:"pointer" }}
                    // {/* onClick={this.handleShow}*/}
                   />
                   </Link>
                
                </h6>

                <div
                  className={(classes.Estimation, "input-group")}
                  // style={{ border: "1px solid #e1e0e0",display:"unset" }}
                >
                  <input
                    type="button"
                    value="-"
                    onClick={this.props.DecrementRevenue}
                    className={classes.button_minus}
                    data-field="quantity"
                  ></input>
                  <input
                    type="number"
                    onChange={this.props.onChangeRevenue}
                    step="1"
                    min="0"
                    value={this.props.revenue}
                    name="quantity"
                    className={classes.quantity_field}
                  />
                  <input
                    type="button"
                    value="+"
                    onClick={this.props.incrementRevenue}
                    className={classes.button_plus}
                    data-field="quantity"
                  ></input>
                </div>

                <h6 className={classes.Estimation}>Activite</h6>

                <select className={classes.inputStyling}>
                  <option value="Salarié(e)">Salarié(e)</option>
                  <option value="Indépendant(e) / Dirigeant(e)">
                    Indépendant(e) / Dirigeant(e)
                  </option>
                  <option value="Profession libérale">
                    Profession libérale
                  </option>
                  <option value="Etudiant(e) / Apprenti(e)">
                    Etudiant(e) / Apprenti(e)
                  </option>
                  <option value="En recherche d'emploi">
                    En recherche d'emploi
                  </option>
                  <option value="Père / Mère au foyer">
                    Père / Mère au foyer
                  </option>
                </select>

                <h6 className={classes.Estimation}>Children</h6>
                <div
                  className="input-group"
                  // style={{ border: "1px solid #e1e0e0" }}
                >
                  <select
                    value={this.props.children}
                    onChange={(event) =>
                      this.props.onChangeChildren(event, false)
                    }
                    name="children"
                    className={classes.inputStyling}
                  >
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </select>
                </div>
                <h6 className={classes.Estimation}>Famille</h6>

                <select
                  value={this.props.marital_status}
                  onChange={(event) => this.props.onChangeFamily(event)}
                  className={classes.inputStyling}
                >
                  <option name="Célibataire" value="single_divorced">
                    Célibataire
                  </option>
                  <option
                    name="Marié(e) / Pacsé(e)"
                    value="married_contracted_widower"
                  >
                    Marié(e) / Pacsé(e)
                  </option>
                  <option name=" Divorcé(e)" value="single_divorced">
                    Divorcé(e)
                  </option>
                  <option
                    name="En concubinchildren"
                    value="married_contracted_widower"
                  >
                    En concubinchildren
                  </option>
                  <option
                    name="Veuf(ve)"
                    value="married_contracted_widower"
                  >
                    Veuf(ve)
                  </option>
                </select>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}
export default CalculateRevenue;
