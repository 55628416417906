import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import CalculateRevenue from "./calculaterevenue/calculaterevenue";
import Content from "./content/content";

class Calculations extends Component {
  constructor(props) {
    super(props);

    this.state = {
        revenue: 0,
        children: 0,
        marital_status: "single_divorced",
        fiscal_share: 1,
        col1: [0, 10064,25659, 73369, 157806],
        col2: [10064, 25659, 73369,157806, 0],
        fiscalTaxAmount:0,
        revenueAfterTax:0,
        fiscalMarginRate:0,
      };
    }
  componentWillMount(){
      this.mainFun(this.state.revenue);
  }
    getFiscalShare = () => {
      let fiscal_share=0;
      // console.log(this.state.marital_status)
      if (this.state.marital_status === "single_divorced") {
        if (this.state.children === "0") {
            fiscal_share= 1;
        } else if (this.state.children === "1") {
            fiscal_share=1.5 ;
        } else if (this.state.children === "2") {
            fiscal_share = 2 ;
        } else if (this.state.children === "3") {
            fiscal_share = 3 ;
        } else if (this.state.children === "4") {
            fiscal_share = 4 ;
        } else if (this.state.children === "5") {
            fiscal_share = 5 ;
        }
      }
      else if (this.state.marital_status === "married_contracted_widower") {
        // console.log(this.state.children)
        if (this.state.children === "0") {
            fiscal_share = 2 ;
        } else if (this.state.children === "1") {
            fiscal_share = 2.5 ;
        } else if (this.state.children === "2") {
            fiscal_share = 3 ;
        } else if (this.state.children === "3") {
            fiscal_share = 4 ;
        } else if (this.state.children === "4") {
            fiscal_share = 5 ;
        } else if (this.state.children === "5") {
            fiscal_share = 6 ;
        }
      }
      // console.log("fiscal share  ", fiscal_share);
       this.setState({fiscal_share:fiscal_share});
       return fiscal_share;
    };
  
  
  withoutFiscalShare=(rev)=>{
    let sub_revenue_col1=0;
    let sub_col2_col1=0;
    // console.log("this.state.revenue",this.state.revenue)
    let revenue=rev*12;  //get first field from calculate revenue i.e; total_revenue
    let min_in_sub1_sub2=0;
    let arr_min_sub1_sub2=[];
    let arr_col3=[0, 0.11, 0.3, 0.41,0.45 ];
     let a=0;
    let tax_without_fs=0;
    if(this.state.marital_status==="single_divorced"){
      a=1;
   }
   else if( this.state.marital_status==="married_contracted_widower"){
    a=2;  
   }
   revenue=revenue/a; 
  //  console.log("revenue "+ revenue)
    let i=0;
    for (i=0; i<5; i++){
      sub_revenue_col1=revenue-this.state.col1[i];
      sub_col2_col1=this.state.col2[i]-this.state.col1[i];
      // console.log("sub_revenue_col1:" +sub_revenue_col1)
      // console.log("sub_col2_col1:" +sub_col2_col1)
      if(sub_revenue_col1 < sub_col2_col1){
        min_in_sub1_sub2=sub_revenue_col1;
      }
      else if(sub_col2_col1<sub_revenue_col1){
        min_in_sub1_sub2=sub_col2_col1;
      }
      // console.log("min_sub: "+min_in_sub1_sub2)
      if(min_in_sub1_sub2<0){
        arr_min_sub1_sub2[i]=0;
      }
      else if(min_in_sub1_sub2>0){
        arr_min_sub1_sub2[i]=min_in_sub1_sub2;
      }
      // console.log("arr_min_sub1_sub2:"+ arr_min_sub1_sub2)
      tax_without_fs=Math.round(tax_without_fs+ (arr_min_sub1_sub2[i]*arr_col3[i]));
      //  console.log(" im  here count:" + result);
    }
    tax_without_fs =a*tax_without_fs;
  //  console.log("tax_without_fs  "+ tax_without_fs )
   return tax_without_fs;
  }
  
  withFiscalShare=(rev)=>{
    let fiscal_share = this.getFiscalShare();
    let sub_revenue_col1=0;
    let sub_col2_col1=0;
    // console.log("this.state.revenue",this.state.revenue)
    let revenue=rev*12;  //get first field from calculate revenue i.e; total_revenue
    let r=revenue/fiscal_share;
    let min_in_sub1_sub2=0;
    let arr_min_sub1_sub2=[];
    let arr_col3=[0, 0.11, 0.3, 0.41,0.45 ];
    let result=0;
    
    let i=0;
    for (i=0; i<this.state.col1.length; i++){
      sub_revenue_col1=r-this.state.col1[i];
      sub_col2_col1=this.state.col2[i]-this.state.col1[i];
      if(sub_revenue_col1 < sub_col2_col1){
        min_in_sub1_sub2=sub_revenue_col1;
      }
      else if(sub_col2_col1<sub_revenue_col1){
        min_in_sub1_sub2=sub_col2_col1;
      }
      if(min_in_sub1_sub2<0){
        arr_min_sub1_sub2[i]=0;
      }
      else if(min_in_sub1_sub2>0){
        arr_min_sub1_sub2[i]=min_in_sub1_sub2;
      }
      result=Math.round(result+ (arr_min_sub1_sub2[i]*arr_col3[i]));
    }
    let tax_with_fs = result*fiscal_share;
    // console.log("tax with share  "+tax_with_fs)
    return tax_with_fs;
  }
  
  
  adjustment_on_fs=()=>{
    let fiscal_share=this.getFiscalShare();
    // console.log("fiscal share: "+ fiscal_share);
    let tax_adjustment=1567*fiscal_share;
    // console.log("tax adjustment "+ tax_adjustment);
    return tax_adjustment;
  }
  
  
  fiscalTaxAmount=(rev)=>{
    let fiscal_tax_amount=0;
    let tax_without_fs=this.withoutFiscalShare(rev);
    let tax_with_fs=this.withFiscalShare(rev);
    let tax_adjustment=this.adjustment_on_fs();
    // console.log("tax_without_fs",tax_without_fs)
    // console.log("tax_with_fs",tax_with_fs)
    // console.log("tax_adjustment",tax_adjustment)
    let diff = tax_without_fs - tax_adjustment;
    //  console.log("diff",diff)
    if (diff>tax_with_fs){
      fiscal_tax_amount=diff;
    }
    else{
      fiscal_tax_amount=tax_with_fs;
    }
    // console.log("fiscal_tax_amount  " + fiscal_tax_amount)
    // console.log("im top of set",fiscal_tax_amount)
    this.setState({fiscal_tax_amount:fiscal_tax_amount})
    // console.log("fiscal tax amount:  "+fiscal_tax_amount)
    return fiscal_tax_amount;
  }
  
  revenueAfterTax=(rev)=>{
    let total_revenue = rev*12;
    let fiscal_tax_amount = this.fiscalTaxAmount(rev); 
    let revenue_after_tax = total_revenue - fiscal_tax_amount;
    return revenue_after_tax;
  }
  fiscalMarginRate=(rev)=>{
      let fiscal_tax_amount=this.fiscalTaxAmount(rev);
      let revenue=rev*12;
      let fiscal_margin_rate=(fiscal_tax_amount/revenue)*100;
      fiscal_margin_rate=fiscal_margin_rate.toFixed(2);
      return fiscal_margin_rate;
  }
  mainFun=(rev)=>{
    let fiscaltaxamount= this.fiscalTaxAmount(rev);
    let revenueaftertax = this.revenueAfterTax(rev);
    let fiscalmarginrate=this.fiscalMarginRate(rev);
    // console.log("fiscalTaxAmount "+ fiscaltaxamount)
    // console.log("revenueAfterTax  "+ revenueaftertax)
    // console.log("fiscalmarginrate  "+ fiscalmarginrate)
    this.setState({fiscalTaxAmount: fiscaltaxamount})
    this.setState({revenueAfterTax: revenueaftertax})
    this.setState({fiscalMarginRate: fiscalmarginrate})
  }
    incrementRevenue = () => {
      this.setState({
        revenue: Number(this.state.revenue) + 1,
      });
    //   console.log("rev : "+ this.revenueAfterTax())
      // this.fiscalTaxAmount();
      this.mainFun(Number(this.state.revenue) + 1);
    };
    DecrementRevenue = () => {
      this.setState({
        revenue: Number(this.state.revenue) - 1,
      });
      // this.fiscalTaxAmount();
      this.mainFun(Number(this.state.revenue) - 1);
    //   this.revenueAfterTax();
      // this.fiscalTaxAmount(this.state.revenue-1);
    };
    onChangeRevenue = (e) => {
      this.setState({ revenue: (e.target.value)});
      // this.fiscalTaxAmount();
      this.mainFun(e.target.value);
    };
  
    onChangeChildren = (event) => {
      this.setState({ children: event.target.value });
    //   this.revenueAfterTax();
      this.fiscalTaxAmount(this.state.revenue);
        this.mainFun(this.state.revenue);
    };
  
    onChangeFamily = (eventsingle) => {
      this.setState({ marital_status: eventsingle.target.value });
    //   this.revenueAfterTax();
      this.fiscalTaxAmount(this.state.revenue);
     this.mainFun(this.state.revenue);
    };
    
  render() {
    return (
      <React.Fragment>
        <Container>
          <Row>
            <Col lg="3">
              <CalculateRevenue
                DecrementRevenue={this.DecrementRevenue}
                onChangeRevenue={this.onChangeRevenue}
                incrementRevenue={this.incrementRevenue}
                getTotalRevenue={this.getTotalRevenue}
                revenue={this.state.revenue}
                onChangeChildren={this.onChangeChildren}
                children={this.state.children}
                onChangeFamily={this.onChangeFamily}
                marital_status={this.state.marital_status}
                TaxEstimate={this.state.fiscalTaxAmount}
                mainFun={this.mainFun}
              />
            </Col>
            <Col lg="9">
              <Content
                revenue={this.state.revenue}
                fiscalTaxAmount={this.state.fiscalTaxAmount}
                revenueAfterTax={this.state.revenueAfterTax}
                fiscalMarginRate={this.state.fiscalMarginRate}
                mainFun={this.mainFun}
                />
            </Col>
          </Row>
          {/* <div>{this.withFiscalShare()}</div> */}
        </Container>
       
      </React.Fragment>
    );
  }
}
export default Calculations;
