import React, { Component } from "react";
import "./App.css";
import Calculations from "./components/calculations";
import {BrowserRouter as Router,Switch, Route} from "react-router-dom";

class App extends Component {
  render() {
    return(
    // <Calculations />
    <div>
   <Router>
     <Switch>
      <Route path="/" exact component={Calculations}/>
      </Switch>
    </Router>
    </div>
    );
  }
}
export default App;
