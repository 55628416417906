import React, { Component } from "react";
import "../content/content.css";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import TextModal from "./TextModal";
import { numberFormat } from "./numberFormat";
import FlagOutlinedIcon from '@material-ui/icons/GolfCourseOutlined';
import surprise from '../../assets/emoticons_surprised-512.webp'
import { BsFillExclamationCircleFill } from "react-icons/bs";
import CountUp, {startAnimation} from 'react-countup';
import $ from 'jquery';


class Content extends Component {
  constructor(props) {
    super(props)
    this.state = {
      firstBlockFlags: [],
      middle: [],
      right: [],
      show: false,
    }
  }

  handleShow = () => {
    this.setState({ show: true })
  }
  handleClose = () => {
    this.setState({ show: false })
  }
  firstCard = () => {
    // this.displayFlags();
    $("#btnDel").click()
    var cal1 = (12 * this.props.revenue * (10 / 100) * (35 / 100));
    var cal2 = 0;
    var cal3 = 32419 * (40 / 100);
    if (cal1 > 4042) {
      cal2 = cal1;
    }
    else {
      cal2 = 4042;
    }
    if (cal3 < cal2) {
      return cal3;
    }
    else {
      return cal2;
    }
  }
  secondCard = () => {
    var cal1 = (18 / 100) * 300000;
    return cal1;
  }
  thirdCard = () => {
    var cal1 = (22 / 100) * 100000;
    return cal1;
  }
  DisplayMessages = () => {
    if (this.props.fiscalTaxAmount == 0) {
      return "vous n'êtes pour le moment pas éligible aux principaux dispositifs de défiscalisation ... Toutefois vous avez le droit a de nombreuses subventions que vous pouvez verifier avec notre partenaire france-subventions.fr"
    }
  }
  firstCardFlags = () => {
    var item = []
    for (let index = 0; index < this.state.firstBlockFlags; index++) {
      item.push(<FlagOutlinedIcon />);
    }
    return item;
  }
  middleCardFlags = () => {
    var item = []

    return item;
  }
  RightCardFlags = () => {
    var item = []
    for (let index = 0; index < this.state.right; index++) {
      item.push(<FlagOutlinedIcon />);
    }
    return item;
  }
  displayFlags = (name) => {
    var item = []
    if (name === "f") {
      if (this.props.fiscalTaxAmount > 0 && this.props.fiscalTaxAmount < 3000) {
        for (let index = 0; index < 2; index++) {
          item.push(<FlagOutlinedIcon style={{ color: "orange" }} />);
        }
        return item;
      }
    }
    else if (name === "m") {
      if (this.props.fiscalTaxAmount > 3000 && this.props.fiscalTaxAmount < 10000) {
        for (let index = 0; index < 2; index++) {
          item.push(<FlagOutlinedIcon style={{ color: "orange" }} />);
        }
        return item;
      }
      if (this.props.fiscalTaxAmount > 15000) {
        for (let index = 0; index < 2; index++) {
          item.push(<FlagOutlinedIcon style={{ color: "orange" }} />);
        }
        return item;
      }
    }
    else if (name === "r") {
      if (this.props.fiscalTaxAmount > 15000) {
        for (let index = 0; index < 2; index++) {
          item.push(<FlagOutlinedIcon />);
        }
        return item;
      }
      if (this.props.fiscalTaxAmount > 3000 && this.props.fiscalTaxAmount < 10000) {
        for (let index = 0; index < 1; index++) {
          item.push(<FlagOutlinedIcon />);
        }
        return item;
      }
    }
  }
  handleStartAnimation = (event) => {
    startAnimation(this.myCountUp)
    startAnimation(this.myCountUp2)
    startAnimation(this.myCountUp3)
    event.preventDefault();
  }
  render() {
    return (
      <React.Fragment>
        <Container>
          <Row>
            <Col sm="12">
              <div>
                <h4 className="rightheading">Dispositifs Potentiels</h4>
                <p className="rightp">
                  Attention: I'economie d'impots ne doit pas etre le seul
                  critere d'investissement
                </p>
              </div>
            </Col>
          </Row>
          {this.props.fiscalTaxAmount == 0?
            <Row>
              <Col className="firstcolumn column_border">
                <div style={{ padding: "98px 30px" }}>
                  <img src={surprise} style={{ width: 40 }}></img>
                  <p style={{ display: "inline" }}>{this.DisplayMessages()}</p>
                </div>

              </Col>
            </Row>
            : <Row>
              <Col sm="12" lg="3" className="firstcolumn column_border">
                <div>

                  <TextModal
                    handleClose={this.handleClose}
                    show={this.state.show}
                  />

                  <p className="rightp1">
                    <div style={{display:"inline"}}>
                    {this.displayFlags("f")}
                    </div>
                    <span className="heading_span">PLAN</span> EPARGNE RETRAITE
                  </p>
                  <h6 className="Economy">
                    Economie d'impots
                    <BsFillExclamationCircleFill style={{ marginLeft: 10, cursor: "pointer" }} onClick={this.handleShow} />
                  </h6>

                  <h5 className="numbers">
                    {/* {this.props.fiscal_tax_amount}*/}
                    {/* <CountUp start={0} end={(this.firstCard())} delay={0} duration={0.8} ref={(countUp) => {
                      this.myCountUp = countUp;
                    }} /> */}
                    <CountUp
                      className="CountUp"
                      start={0}
                      end={this.firstCard()}
                      duration={0.8}
                      ref={(countUp) => {
                        this.myCountUp = countUp;
                      }}
                    />
                    {/* </CountUp> */}
                    <button id="btnDel" className="Button" style={{display:"none"}} onClick={this.handleStartAnimation}>Start animation</button>
                    {/* {(this.firstCard())}{" "} */}
                    <span className="euro">€</span>
                  </h5>
                </div>
                <Link target="_blank" to={{ pathname: "https://nrgpartners.fr/" }}>
                  <div className="bottom_div bottom_div_lr">
                    <p className="bottom_div_text">En savior plus</p>
                  </div>
                </Link>
              </Col>
              <Col sm="12" lg="4" className="col_lg_4 middlecolumn column_border">
                <div>
                  <div className="col_md_top">
                    <p className="col_md_heading">
                    <div style={{display:"inline"}}>
                      {this.displayFlags("m")}
                      </div>
                      {/* {this.state.middle?.map(x =>
                        <FlagOutlinedIcon style={{ color: "orange" }} />
                      )} */}
                    MEILLEUR RESULTAT</p>
                  </div>
                  <p className="rightp1_md">
                    IMMOBILIER NEUF <span className="heading_span">PINEL</span>
                  </p>
                  <h6 className="Economy">
                    Economie d'impots
                    <BsFillExclamationCircleFill
                      style={{ marginLeft: 10, cursor: "pointer" }}
                      onClick={this.handleShow}
                    />
                  </h6>
                  <h5 className="numbers numbers_md">
                    {/* <CountUp start={0} end={(this.secondCard())} delay={0} duration={0.8}>
                      {({ countUpRef }) => (
                        <div>
                          <span ref={countUpRef} />
                        </div>
                      )}
                    </CountUp> */}
                    <CountUp
                      className="CountUp"
                      start={0}
                      end={this.secondCard()}
                      duration={0.8}
                      ref={(countUp) => {
                        this.myCountUp2 = countUp;
                      }}
                    />
                    {/* {numberFormat(this.secondCard())}{" "} */}
                    <span className="euro">{" "}€</span>
                  </h5>
                  <Link target="_blank" to={{ pathname: "https://nrgpartners.fr/" }}>
                    <div className="bottom_div_md">
                      <p className="bottom_div_text">En savior plus</p>
                    </div>
                  </Link>
                </div>
              </Col>

              <Col
                sm="12"
                lg="3"
                className="firstcolumn third_column column_border"
              >
                <div>
                  <p className="rightp1">
                  <div style={{display:"inline"}}>
                    {this.displayFlags("r")}
                    </div>
                    <span className="heading_span">LMNP</span> CENSI-BOUVARD
                  </p>
                  <h6 className="Economy">
                    Economie d'impots
                    <BsFillExclamationCircleFill style={{ marginLeft: 10, cursor: "pointer" }} onClick={this.handleShow} />
                  </h6>
                  <h5 className="numbers">
                    {/* <CountUp start={0} end={(this.thirdCard())} delay={0} duration={0.8}>
                      {({ countUpRef }) => (
                        <div>
                          <span ref={countUpRef} />
                        </div>
                      )}
                    </CountUp> */}
                     <CountUp
                      className="CountUp"
                      start={0}
                      end={this.thirdCard()}
                      duration={0.8}
                      ref={(countUp) => {
                        this.myCountUp3 = countUp;
                      }}
                    />
                    {/* {numberFormat(this.thirdCard())}{" "}€ */}
                    <span className="euro">{" "}€</span>
                  </h5>
                  <Link target="_blank" to={{ pathname: "https://nrgpartners.fr/" }}>
                    <div className="bottom_div bottom_div_lr">
                      <p className="bottom_div_text">En savoir plus</p>
                    </div>
                  </Link>
                </div>
              </Col>
            </Row>
          }
          <Row>
            <Col sm="12">
              <h6 className="rightheading11">Autres Dispositifs</h6>
              <p className="rightp11">
                Ces depositifs semblent moins pertinets pour vous
              </p>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
export default Content;
